import { createNativeStackNavigator } from "@react-navigation/native-stack";
import HomeScreen from "../screens/Main/home/HomeScreen";
import ProductsScreen from "../screens/Main/products/ProductsMain";
import CartScreen from "../screens/Main/cart/CartMain";
import RecieptScreen from "../screens/Main/receipts/RecieptsMain";
import GroupCartMain from "../screens/Main/groupcart/GroupCartMain";
import SuccessScreen from "../screens/Main/response/SuccessScreen";
// he;llooo
import PaymentResponsescreen from "../screens/Main/receipts/PaymentResponse";
import CheckoutCompleteScreen from "../screens/Main/receipts/CheckOutComplete";
import OrderScreen from "../screens/Main/response/OrderScreen";
import ErrorScreen from "../screens/Main/response/ErrorScreen";
const Stack = createNativeStackNavigator();

const MainNavigation = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="HomeScreen" component={HomeScreen} />
      <Stack.Screen name="OfferScreen" component={ProductsScreen} />
      <Stack.Screen name="CartScreen" component={CartScreen} />
      <Stack.Screen name="RecieptScreen" component={RecieptScreen} />
      <Stack.Screen name="GroupCart" component={GroupCartMain} />
      <Stack.Screen name="SuccessScreen" component={SuccessScreen} />
      <Stack.Screen
        name="PaymentResponsescreen"
        component={PaymentResponsescreen}
      />
      <Stack.Screen
        name="CheckoutCompleteScreen"
        component={CheckoutCompleteScreen}
      />
      <Stack.Screen name="ErrorScreen" component={ErrorScreen} />
      <Stack.Screen name="OrderScreen" component={OrderScreen} />
    </Stack.Navigator>
  );
};

export default MainNavigation;
//initialRouteName="HomeScreen"
