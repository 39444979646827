import { COLORS, IMAGES, FONTS } from "../../../constants/index";
import {
  StyleSheet,
  FlatList,
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  SafeAreaView,
  Image,
  Modal,
  TextInput,
  ScrollView,
  Clipboard,
  Share,
  ActivityIndicator,
  Alert,
} from "react-native";
import React, { useEffect, useState, useRef } from "react";
import { ArrowLeft, FacebookIcon, InstagramIcon } from "lucide-react";
import { ShoppingCart } from "lucide-react";
import { UserPlus } from "lucide-react";
import axios from "axios";
import SearchBar from "../../../components/SearchBar";
import throttle from 'lodash/throttle';
import Layouts from "../../Layouts/ScreenView";

// import { Searchbar } from 'react-native-paper';
import { useCart } from "../../../context/SinglecartContext";
import {
  X,
  CopyIcon,
  ChevronDown,
  ChevronUp,
  Share as Sharef,
} from "lucide-react";
import Button from "../../../components/Button";
import LinearGradient from "react-native-web-linear-gradient";
// import Layouts from "../../Layouts/ScreenView";
import { useSplitContext } from "../../../context/SplitBillsContext";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
// import { FlatList } from "react-native-web";
import { useGroupCart } from "../../../context/GroupmanagerContext";
import ExportedRenderItems from "./components/ExportedRender";
import {
  WhatsappShareButton,
  FacebookShareButton,
  WhatsappIcon,
  TelegramIcon,
  InstapaperShareButton,
  TelegramShareButton,
} from "react-share";

import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { useMerchantStore } from "../../../store/merchant_store";

function ProductsScreen({ navigation, route }) {
  const { groupno, memberId } = route.params || {};
  const merchantStore = useMerchantStore((state) => state.merchant);
  const { merchantData } = route.params || merchantStore;
  const [showHeader, setShowHeader] = useState(true);


  const [value, setValue] = useState(0);
  const [count, setCount] = useState(0);
  const [groupModal, setGroupModal] = useState(false);
  const [descModal, setDescModal] = useState(false);
  ///-----------------------------------
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([])
  const [nextUrl, setNextUrl] = useState(null);
  const [loader, setLoaders] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const searchTimeout = useRef(null);  // To track search debounce

 //Debounce utility function
const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};


  const fetchProducts = async (url, append = true) => {
    try {
      setLoaders(true);
      
      // Prevent multiple concurrent requests by using a state to track ongoing requests
      const response = await axios.get(url);
      const data = response.data;
  
      // If appending, add to the existing products. If not, replace.
      setProducts((prevProducts) => append ? [...prevProducts, ...data.results] : data.results);
      setNextUrl(data.next); // Set the next page URL for further loading
  
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoaders(false);
    }
  };

    // Fetch initial products on component mount
    // useEffect(() => {
    //   const initialUrl = `https://backoffice.route.money/api/v1/business/merchant/products/${merchantData.slug}?limit=50&offset=0`;
    //   fetchProducts(initialUrl);
    // }, [merchantData.slug]);

    // Fetch initial products on component mount
useEffect(() => {
  const initialUrl = `https://backoffice.route.money/api/v1/business/merchant/products/${merchantData.slug}?limit=50&offset=0`;
  fetchProducts(initialUrl, false); // Don't append initially, just set the products
}, [merchantData.slug]);
  
    // // Load more products when reaching the end of the list
    // const loadMoreProducts = () => {
    //   if (nextUrl && !loader) {
    //     fetchProducts(nextUrl);
    //   }
    // };

    // Throttle to limit how often loadMoreProducts is called
const throttledLoadMore = throttle(() => {
  if (nextUrl && !loader) {
    fetchProducts(nextUrl);
  }
}, 1500); // 1.5 seconds throttle

// Load more products when reaching the end of the list
const loadMoreProducts = () => {
  throttledLoadMore();
};
  
  const fetchFilteredData = async (query) => {

    // if (!query) return;

    if (!query) {
      setFilteredItems(products);
      return;
    }

    setIsSearching(true);
    try {
      const response = await axios.get(
        `https://backoffice.route.money/api/v1/business/merchant/products/${merchantData.slug}?search=${query}`
      );

      setFilteredItems(response.data.results);
      //console.log('merchant ',merchantData.slug); // Handle the results here
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setIsSearching(false);
    }
  }

  ///-----------------------------------
  const { getNumberOfItems, stateLayout, loading } = useCart();
  const {
    mediaSplitshareLinks,
    openMediaModalToShareLinks,
    generateUrl,
    requestedAmount,
    myPayments,
    totalAmount,
  } = useSplitContext();

  const [searchQuery, setSearchQuery] = useState("");
  const [visibleDropdown, setVisibleDropdown] = useState(false);

  const toggleDropdown = () => {
    setVisibleDropdown(!visibleDropdown);
  };

  // Modal offers
  const openDescModal = () => {
    setDescModal(true);
  };

  const closeDescModal = () => {
    setDescModal(false);
  };

  const renderDropdown = (description) => {
    if (visibleDropdown) {
      return <Text style={styles.dropdown}>{description}.</Text>;
    }
  };

  const ounterUpdates = (sign) => {
    if (sign === "+") {
      setCount((prev) => prev + 1);
    } else if (sign === "-") {
      if (count <= 0) {
        alert("Item number must be positive");
        setCount(0);
      } else {
        setCount((prev) => prev - 1);
      }
    }
  };

  // Go to cart screen
  const hangleGoToCartPage = () => {
    navigation.navigate("CartScreen", { getNumberOfItems, merchantData });
  };

  const openGroupModel = () => setGroupModal(true);
  const closeTheModal = (value) => setGroupModal(false);
  const [groupPaymentPlan, setGroupPaymentPlan] = useState(false);

  const [stickyNavigation, setStickyNavigation] = useState(false);
  const handleScroll = (event) => {
    const offsetY = event.nativeEvent.contentOffset.y;
    //console.log('scrolling event ',offsetY);
  
      if (offsetY > 0 && offsetY >= 140) {
        setStickyNavigation(false);
        setShowHeader(true); // show header when scrolling down
      } else {
        setStickyNavigation(false);
        setShowHeader(false); // Hide header when scrolling up
      }
  };

  // Groupc cart functionality
  const [groupId, setGroupId] = useState("");
  const [personName, setPersonName] = useState("");
  const [personEmail, setPersonEmail] = useState("");
  const [personMoblie, setPersonMobile] = useState("");
  const {
    createGroup,
    joinGroup,
    leaveGroup,
    addToCart,
    removeFromCart,
    clearCart,
    state,
  } = useGroupCart();

  const groupCartNavigation = () =>
    navigation.navigate("GroupCart", { groupId: groupId, memberId: memberId });
  const createGroupSuccessful = () => {
    if (!groupId || !personName || !personEmail || !personMoblie) {
      Alert.alert("You must fill all the fields provided to create a group!!!");
    }
    try {
      groupId && createGroup(groupId);
      state.group.length > 0 ? setGroupModal(true) : setGroupModal(false);
      groupCartNavigation();
    } catch (error) {
      console.error(error);
    }
    Alert.alert(
      `Hello ${personName}. You created ${groupId} as group name, so you are the admin and all the rights to manage the group. Now invite people.`
    );
  };

  const [categoryNames, setCategoryNames] = useState([]);
  useEffect(() => {
    const categories = [...new Set(products.map((item) => item.category_name))];
// 



    // categories.unshift("Offers");
    const checkOffer= 
    setCategoryNames(categories);
    let new_cat = categories.unshift('All');
    if (products.some((item) => item.offer_price > 0)) {
      let new_cat2 = categories.unshift('Offers');
      console.log("offer");
    } 
   
    // console.log("Categories: ", new_cat);
  }, [products]);

  const [selectedFilterActive, setSelectedFilterActive] = useState("All");
  const [subcategoryNames, setSubcategoryNames] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const flatListRef = useRef(null);

  const handleInputChange = (text) => {
    setSearchQuery(text); // Update search query immediately
  };

  const handleSearch = (query) => {
    if (query) {
      setIsSearching(true); // Show loading state
      fetchFilteredData(query).finally(() => {
        setIsSearching(false); // Hide loading state
      });
    }
  };


  const [subcategory, setSubCategory] = useState(null);
  const handleActiveSelect = (category, index) => {
    setSelectedFilterActive(category);
    setSubCategory(null);

     if (category === "Offers") {
      const offerItems = products.filter((item) => item.offer_price > 0);
     // console.log('offer selected');
      setFilteredItems(offerItems);
    } else {

    const subcategories = products
      .filter(
        (item) => item.category_name === category && item.subcategory_name
      )
      .map((item) => item.subcategory_name);
    const uniqueSubcategories = new Set(subcategories);
    // Convert back to an array if needed
    const uniqueArray = Array.from(uniqueSubcategories);
    setSubcategoryNames(uniqueArray);

    // const filtered = products.filter((item) => {
    //   if (item.category_name === category) {
    //     if (!item.subcategory_name) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   } else {
    //     return true;
    //   }
    // });
    const filtered = products.filter((item) => item.category_name === category);

    const sortedFiltered = filtered.sort((a, b) => {
      if (a.category_name === category && !a.subcategory_name) {
        return -1;
      } else if (b.category_name === category && !b.subcategory_name) {
        return 1;
      } else {
        return 0;
      }
    });
    setFilteredItems(sortedFiltered);
    setFilteredItems(filtered);
  }
    flatListRef.current.scrollToIndex({ index });
  };

  const subcategoryRef = useRef(null);

  const handleSubcategoryClicks = (item, index) => {
    console.log("item: ", item);
    setSubCategory(item);
    // const filtered = filteredItems.filter((product) => {
    //   if (selectedFilterActive === product.subcategory_name) {
    //     return product.subcategory_name === item;
    //   }
    //   return true;
    // });
   
    const filtered = products.filter(
      (product) => product.subcategory_name === item
    );

  
    setFilteredItems(filtered);
    subcategoryRef.current.scrollToIndex({ index });
  };

  // Share links fort he social media platfroms
  const splitShareDataToTransfer = {
    total_amount: totalAmount,
    request_payments: requestedAmount,
    my_payments: myPayments,
  };
  const sharedUrl = generateUrl(splitShareDataToTransfer);

  const copyToClipboard = (linktoCopyToClipBoard) => {
    Clipboard.setString(linktoCopyToClipBoard);
    alert("Link copied to clipboard!, Paste to share.");
    openMediaModalToShareLinks();
  };

  // Alternative share
  const openShareMenu = async (sharedUrl) => {
    try {
      await Share.share({
        message: sharedUrl,
      });
    } catch (error) {
      console.error("Error sharing link:", error.message);
    }
  };

  // ellipses
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  const truncatedProducts = products.map(product => ({
    ...product,
    truncatedName: truncateText(product.name,50),
    truncatedDescription: truncateText(product.description,50),
   
   
  }));


const truncateMerchName = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};
 
  
  return (
    <Layouts style={{flex:1,backgroundColor:COLORS.backgroundDark }}>

      <View style={[styles.container,{flex:1,alignSelf: stateLayout}]}>
    {stickyNavigation &&( 
        <View style={styles.navigationLinks}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <ArrowLeft color="white" size={18} />
          </TouchableOpacity>
        </View>
   )}
        {merchantData ? (
          <ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
            style={{ flex: 1,width: "100%" }}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            onScroll={handleScroll}
            scrollEventThrottle={16}
          >

            <View style={styles.offerViewPage}>
              <View style={styles.topImageView}>
                <View style={styles.imagecover}>
                  <Image
                    source={`https://backoffice.route.money/api/v1${merchantData.cover_image}`}
                    style={styles.imageset}
                  />
                </View>
                <LinearGradient
                  colors={[
                    "rgba(30, 32, 46, 1.1)",
                    "rgba(30, 32, 46, 1.0)",
                    "rgba(30, 32, 46, .95)",
                    "rgba(30, 32, 46, .80)",
                    "rgba(30, 32, 46, .70)",
                    "rgba(30, 32, 46, .60)",
                    "rgba(30, 32, 46, .50)",
                    "rgba(30, 32, 46, .40)",
                    "rgba(30, 32, 46, .30)",
                    "rgba(30, 32, 46, .20)",
                    "rgba(30, 32, 46, .10)",
                    "rgba(30, 32, 46, .05)",
                    "rgba(30, 32, 46, .04)",
                    "rgba(30, 32, 46, .03)",
                    "rgba(30, 32, 46, .02)",
                    "rgba(30, 32, 46, .01)",
                    "rgba(30, 32, 46, .00)",
                  ]}
                  start={{ x: 0, y: 1 }}
                  end={{ x: 0, y: 0 }}
                  angle={180}
                  style={styles.imageZindexValues}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <View style={styles.avatarImageDisplay}>
                        <Image
                          source={`https://backoffice.route.money/api/v1${merchantData.profile_image}`}
                          style={styles.imageset}
                        />
                      </View>
                      <View style={styles.textPreview}>
                        <Text style={[styles.title, { fontWeight: 700 }]}>
                        {truncateMerchName(merchantData.name,18)}
                        </Text>
                        <View
                          style={{
                            justifyContent: "flex-start",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={[styles.title, { marginRight: 5 }]}>
                            {truncateMerchName(merchantData.location,17)}
                       
                          </Text>

                          </View>
                          {/* <TouchableOpacity onPress={openDescModal}>
                          
                            {descModal ? (
                              <ChevronUp
                                stroke="white"
                                fill="transparent"
                                width={18}
                                height={18}
                              />
                            ) : (
                              <ChevronDown
                                stroke="white"
                                fill="transparent"
                                width={18}
                                height={18}
                              />
                            )}
                           </TouchableOpacity> */}
                    
                      </View>
                    </View>

                    <View
                      style={[
                        styles.cartflex,
                        {
                          marginLeft: 15,
                          backgroundColor:
                          getNumberOfItems() > 0 && COLORS.backgroundDark,
                          padding: 5,
                          borderTopLeftRadius: 15,
                          position: "sticky",
                          top: -1,
                        },
                      ]}
                    >
                     {/* {state.length > 0 ? (
                        <TouchableOpacity
                          style={[
                            styles.buttonflexGroup,
                            {
                              marginRight: 5,
                              paddingHorizontal: 10,
                              paddingVertical: 2,
                            },
                          ]}
                          onPress={groupCartNavigation}
                        >
                          <UserPlus size={16} color="white" />
                          <Text style={styles.textColor}>Group Order</Text>
                        </TouchableOpacity>
                      ) : (
                        <TouchableOpacity
                          style={[
                            styles.buttonflexGroup,
                            {
                              marginRight: 5,
                              paddingHorizontal: 10,
                              paddingVertical: 2,
                            },
                          ]}
                          onPress={openGroupModel}
                        >
                          <UserPlus size={16} color="white" />
                          <Text style={styles.textColor}>Create Group</Text>
                        </TouchableOpacity>
                      )}  */}

                      <TouchableOpacity
                        style={styles.cartContainer}
                        onPress={hangleGoToCartPage}
                      >
                        {getNumberOfItems() > 0 && (
                          <Text
                            style={[
                              styles.cartCounter,
                              count > 9 && { width: 30, paddingHorizontal: 4 },
                            ]}
                          >
                            {getNumberOfItems()}
                          </Text>
                        )}
                        <ShoppingCart
                          size={14}
                          color={getNumberOfItems() > 0 ? "skyblue" : "white"}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                </LinearGradient>
              </View>

              <View style={styles.bottomContentView}>
                <View
                  style={{
                    position: "sticky",
                    top: -1,
                    right: 0,
                    left: 0,
                    backgroundColor: "rgba(30, 32, 46, 1.1)",
                    zIndex: 100,
                    paddingBottom: 10,
                  }}
                >
                  {stickyNavigation && (
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingVertical: 20,
                        marginTop: 20,
                        marginBottom: 5,
                      }}
                    >
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <View style={styles.avatarImageDisplay}>
                          <Image
                           source={`https://backoffice.route.money/api/v1${merchantData.profile_image}`}
                            style={styles.imageset}
                          />
                        </View>
                        <View style={styles.textPreview}>
                          <Text style={[styles.title, { fontWeight: 700 }]}>
                            {merchantData.name}
                          </Text>
                          <Text style={styles.title}>
                            {merchantData.location}......
                          </Text>
                        </View>
                      </View>

                      <View
                        style={[
                          styles.cartflex,
                          {
                            marginLeft: 15,
                            backgroundColor:
                              getNumberOfItems() > 0 && COLORS.backgroundDark,
                            padding: 5,
                            borderTopLeftRadius: 15,
                            borderBottomRightRadius: 15,
                          },
                        ]}
                      >
                        {state.length > 0 ? (
                          <TouchableOpacity
                            style={[
                              styles.buttonflexGroup,
                              {
                                marginRight: 5,
                                paddingHorizontal: 10,
                                paddingVertical: 2,
                              },
                            ]}
                            onPress={groupCartNavigation}
                          >
                            <UserPlus size={16} color="white" />
                            <Text style={styles.textColor}>Group Order</Text>
                          </TouchableOpacity>
                        ) : (
                          <TouchableOpacity
                            style={[
                              styles.buttonflexGroup,
                              {
                                marginRight: 5,
                                paddingHorizontal: 10,
                                paddingVertical: 2,
                              },
                            ]}routemoney-react-webapp
                            onPress={openGroupModel}
                          >
                            <UserPlus size={16} color="white" />
                            <Text style={styles.textColor}>Create Group</Text>
                          </TouchableOpacity>
                        )}

                        <TouchableOpacity
                          style={styles.cartContainer}
                          onPress={hangleGoToCartPage}
                        >
                          {getNumberOfItems() > 0 && (
                            <Text
                              style={[
                                styles.cartCounter,
                                count > 9 && {
                                  width: 30,
                                  paddingHorizontal: 4,
                                },
                              ]}
                            >
                              {getNumberOfItems()}
                            </Text>
                          )}
                          <ShoppingCart
                            size={14}
                            color={getNumberOfItems() > 0 ? "skyblue" : "white"}
                          />
                        </TouchableOpacity>
                      </View>
                    </View>
                  )}

              <View style={styles.searchContainer}>

              <SearchBar
                searchTerm={searchQuery}
                onChangeText={handleInputChange}
                onSearch={handleSearch}
                isSearching={isSearching}
                isTyping={isTyping}
              />
              </View>


                  <View style={styles.buttonCategories}>
                    <View style={styles.buttonflex}>
                      <View style={{ width: "100%" }}>
                        {categoryNames && (
                          <FlatList
                            ref={flatListRef}
                            data={categoryNames}
                            // data={categoryNames.sort()}
                            horizontal
                            showsHorizontalScrollIndicator={false}
                            keyExtractor={(item, index) => index.toString()}
                            renderItem={({ item, index }) => (
                              <TouchableOpacity
                                style={[
                                  styles.filterOffersBtns,
                                  {
                                    backgroundColor:
                                      selectedFilterActive === item
                                        ? COLORS.gray
                                        : "",
                                  },
                                ]}
                                onPress={() => handleActiveSelect(item, index)}
                              >
                                <Text style={styles.textColor}>{item}</Text>
                              </TouchableOpacity>
                            )}
                          />
                        )}
                      </View>

                      <View style={{ width: "100%", marginTop: 5 }}>
                        {subcategoryNames.length > 0 && (
                          <FlatList
                            ref={subcategoryRef}
                            data={subcategoryNames}
                            horizontal
                            showsHorizontalScrollIndicator={false}
                            keyExtractor={(item, index) => index.toString()}
                            renderItem={({ item, index }) => (
                              <TouchableOpacity
                                style={[
                                  styles.filterOffersBtns,
                                  {
                                    backgroundColor:
                                      subcategory === item ? COLORS.blue : "",
                                  },
                                ]}
                                onPress={() =>
                                  handleSubcategoryClicks(item, index)
                                }
                              >
                                <Text style={styles.textColor}>{item}</Text>
                              </TouchableOpacity>
                            )}
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>

    
            
                  
                {
               
                  
         

                  

                  <ExportedRenderItems
                  count={count}
                 // counterUpdates={counterUpdates}
                  groupno={groupno}
                  memberId={memberId}
                  filteredItems={filteredItems}
                  products={truncatedProducts}
                  onEndReached={loadMoreProducts}
                  onEndReachedThreshold={0.5}
                  ListFooterComponent={loader && <ActivityIndicator />

                  }
                />
                }
              </View>
            </View>
          </ScrollView>
        ) : (
          <ActivityIndicator />
        )}

        {/* Create Group Modal */}
        <Modal animationType="slide" visible={groupModal} transparent={true}>
          <View style={[styles.modalContainer, { alignSelf: stateLayout }]}>
          {/* <View style={[styles.modalContainer]}> */}
            <View style={styles.modalContent}>
              <TouchableOpacity style={styles.madalPayAmount}>
                <View style={styles.barTopSectionView}></View>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.topCloseModal}
                onPress={closeTheModal}
              >
                <X color="white" size={18} />
              </TouchableOpacity>
              <View style={styles.groupTitle}>
                <Text
                  style={{
                    ...FONTS.subsmalltext,
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Create Group
                </Text>
              </View>
              {/* Modal content */}
              <View style={styles.windowDisplay}>
                <TextInput
                  type="text"
                  placeholder="Group name"
                  style={styles.singleInput}
                  onChangeText={(group) => setGroupId(group)}
                  value={groupId}
                />
                <TextInput
                  type="text"
                  placeholder="Enter your name"
                  style={styles.singleInput}
                  onChangeText={(name) => setPersonName(name)}
                  value={personName}
                />
                <TextInput
                  type="text"
                  placeholder="Enter your email"
                  style={styles.singleInput}
                  onChangeText={(email) => setPersonEmail(email)}
                  value={personEmail}
                />
                <TextInput
                  type="phone"
                  placeholder="Enter your phonenumber"
                  style={styles.singleInput}
                  onChangeText={(phone) => setPersonMobile(phone)}
                  value={personMoblie}
                />
              </View>
              <View style={styles.modalButtonOrder}>
                {/* <Button
                  text="Create Group"
                  style={styles.button}
                  onPress={createGroupSuccessful}
                /> */}
              </View>
            </View>
          </View>
        </Modal>

        {/* Payment mode (Group)>> Pay for everyone || Everyone pay for themselves */}
        <Modal
          animationType="slide"
          visible={groupPaymentPlan}
          transparent={true}
        >
          <View style={[styles.modalContainer, { alignSelf: stateLayout }]}>
          {/* <View style={[styles.modalContainer]}> */}
            <View style={styles.modalContainerGroupPayment}>
              <TouchableOpacity style={styles.madalPayAmount}>
                <View style={styles.barTopSectionView}></View>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.topCloseModal}
                onPress={closeTheModal}
              >
                <X color="white" size={18} />
              </TouchableOpacity>
              <View style={styles.groupTitle}>
                <Text
                  style={{
                    ...FONTS.subsmalltext,
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Select an option
                </Text>
              </View>

              <View style={styles.modalButtonOrderGroupChart}>
                <Button
                  text="You're paying for everyone"
                  style={styles.buttonGroup}
                />
                <Button
                  text="Everyone paying for themselves"
                  style={[
                    styles.buttonGroup,
                    {
                      backgroundColor: "transparent",
                      borderWidth: 1,
                      borderColor: COLORS.blue,
                    },
                  ]}
                />
              </View>
            </View>
          </View>
        </Modal>

        {/* Grou[ share links >> Through some media */}
        <Modal
          animationType="slide"
          visible={mediaSplitshareLinks}
          transparent={true}
        >
          <View style={[styles.modalContainer, { alignSelf: stateLayout }]}>
          {/* <View style={[styles.modalContainer]}> */}
            <View style={styles.modalContainerGroupPaymentLink}>
              <TouchableOpacity style={styles.madalPayAmount}>
                <View style={styles.barTopSectionView}></View>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.topCloseModal}
                onPress={openMediaModalToShareLinks}
              >
                <X color="white" size={18} />
              </TouchableOpacity>
              <View style={styles.groupTitle}>
                <Text
                  style={{
                    ...FONTS.subsmalltext,
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Share Group Meal Link
                </Text>
              </View>
              <View style={styles.modalButtonOrderGroupChart}>
                <View style={styles.linktoCopy}>
                  <Text style={{ ...FONTS.smallfineText, fontSize: 11 }}>
                    {sharedUrl.slice(0, 62) + "..."}
                  </Text>
                  <TouchableOpacity onPress={() => copyToClipboard(sharedUrl)}>
                    <CopyIcon
                      color="white"
                      size={18}
                      style={{ cursor: "pointer" }}
                    />
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.modalButtonOrderGroupChart}>
                <Text
                  style={{
                    ...FONTS.subsmalltext,
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Sharing Options
                </Text>
                <View style={styles.inputsSendMethods}>
                  <WhatsappShareButton
                    style={styles.singleButton}
                    url={sharedUrl}
                  >
                    <View style={styles.imagePay}>
                      <WhatsappIcon style={styles.imagePayIcon} />
                    </View>
                    <Text
                      style={{
                        ...FONTS.subsmalltext,
                        fontSize: 10,
                        textAlign: "start",
                        marginTop: 10,
                      }}
                    >
                      Whatsapp
                    </Text>
                  </WhatsappShareButton>
                  <FacebookShareButton
                    style={styles.singleButton}
                    url={sharedUrl}
                  >
                    <View style={styles.imagePay}>
                      <FacebookIcon style={styles.imagePayIcon} />
                    </View>
                    <Text
                      style={{
                        ...FONTS.subsmalltext,
                        fontSize: 10,
                        textAlign: "center",
                        marginTop: 10,
                      }}
                    >
                      Facebook
                    </Text>
                  </FacebookShareButton>
                  <TouchableOpacity
                    style={styles.singleButton}
                    onPress={() => openShareMenu(sharedUrl)}
                  >
                    <View style={styles.imagePay}>
                      <Image
                        source={IMAGES}
                        resizeMode="contain"
                        style={styles.imagePayIcon}
                      />
                    </View>
                    <Text
                      style={{
                        ...FONTS.subsmalltext,
                        fontSize: 10,
                        textAlign: "center",
                        marginTop: 10,
                        marginLeft: 6,
                      }}
                    >
                      +
                    </Text>
                  </TouchableOpacity>
                  <InstapaperShareButton
                    style={styles.singleButton}
                    url={sharedUrl}
                  >
                    <View style={styles.imagePay}>
                      <InstagramIcon style={styles.imagePayIcon} />
                    </View>
                    <Text
                      style={{
                        ...FONTS.subsmalltext,
                        fontSize: 10,
                        textAlign: "center",
                        marginTop: 10,
                      }}
                    >
                      Instagram
                    </Text>
                  </InstapaperShareButton>
                  <TelegramShareButton
                    style={styles.singleButton}
                    url={sharedUrl}
                  >
                    <View style={styles.imagePay}>
                      <TelegramIcon style={styles.imagePayIcon} />
                    </View>
                    <Text
                      style={{
                        ...FONTS.subsmalltext,
                        fontSize: 10,
                        textAlign: "center",
                        marginTop: 10,
                      }}
                    >
                      Telegram
                    </Text>
                  </TelegramShareButton>
                </View>
              </View>
            </View>
          </View>
        </Modal>

        {/* Description Modal */}
        <Modal animationType="slide" visible={descModal} transparent={true}>
          <View style={[styles.modalContainer, { alignSelf: stateLayout }]}>
          {/* <View style={[styles.modalContainer]}> */}
            <View style={[styles.modalContent, { height: hp(99) }]}>
              <TouchableOpacity style={styles.madalPayAmount}>
                <View style={styles.barTopSectionView}></View>
              </TouchableOpacity>
              <View style={styles.navigationLinks}>
                <TouchableOpacity onPress={closeDescModal}>
                  <X
                    color="white"
                    size={20}
                    style={{
                      backgroundColor: "gray",
                      borderRadius: 50,
                      padding: 3,
                    }}
                  />
                </TouchableOpacity>
              </View>

              {/* Modal content */}
              <View style={styles.windowDisplay}>
                <ScrollView
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: 35,
                    }}
                  >
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <View style={styles.avatarImageDisplay}>
                        <Image
                          source={`https://backoffice.route.money/api/v1${merchantData.profile_image}`}
                          style={styles.imageset}
                        />
                      </View>
                      <View style={styles.textPreview}>
                        <Text style={[styles.title, { fontWeight: 700 }]}>
                          {merchantData.name}
                        </Text>
                        <View
                          style={{
                            justifyContent: "flex-start",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={[styles.title, { marginRight: 5 }]}>
                            {merchantData.location}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        backgroundColor: "grey",
                        borderRadius: 50,
                        padding: 5,
                      }}
                    >
                      <Sharef
                        width={20}
                        height={20}
                        fill="none"
                        color="white"
                      />
                    </View>
                  </View>
                  <View style={{ marginVertical: 10 }}>
                    <Paper
                      square
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "none",
                      }}
                    >
                      <Tabs
                        value={value}
                        indicatorColor="primary"
                        style={{ paddingVertical: 1 }}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                      >
                        <Tab
                          label="About"
                          color="white"
                          style={{
                            color: "white",
                            fontSize: 11,
                            margin: 0,
                            padding: 0,
                          }}
                        />
                        <Tab
                          label="Reviews"
                          color="white"
                          style={{
                            color: "white",
                            fontSize: 11,
                            margin: 0,
                            padding: 0,
                          }}
                        />
                      </Tabs>
                    </Paper>
                    {value == 0 ? (
                      <View style={{ paddingVertical: 10, marginVertical: 10 }}>
                        <View
                          style={{
                            borderRadius: 10,
                            backgroundColor: "#343643",
                            paddingVertical: 15,
                            paddingHorizontal: 4,
                          }}
                        >
                          <View
                            style={{
                              margin: 15,
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              source={IMAGES.loc}
                              style={{
                                width: 20,
                                height: 20,
                                marginRight: 10,
                                resizeMode: "contain",
                              }}
                            />
                            <Text style={{ color: "white" }}>
                              {merchantData.location}
                            </Text>
                          </View>

                          <View
                            style={{
                              margin: 15,
                              marginBottom: 1,
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              source={IMAGES.tim}
                              style={{
                                width: 16,
                                height: 16,
                                marginRight: 10,
                                resizeMode: "contain",
                              }}
                            />
                            <Text
                              style={{
                                color: "white",
                                fontSize: 13,
                                fontWeight: "600",
                                marginRight: 2,
                              }}
                            >
                              Open now
                            </Text>
                            <ChevronDown
                              stroke="white"
                              fill="transparent"
                              width={18}
                              height={18}
                            />
                          </View>
                          <View
                            style={{
                              marginHorizontal: 15,
                              paddingHorizontal: 30,
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Text style={{ color: "white", fontSize: 12 }}>
                                Monday
                              </Text>
                              <Text style={{ color: "white", fontSize: 12 }}>
                                11:00 - 22:00
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Text style={{ color: "white", fontSize: 12 }}>
                                Tuesday
                              </Text>
                              <Text style={{ color: "white", fontSize: 12 }}>
                                11:00 - 22:00
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Text style={{ color: "white", fontSize: 12 }}>
                                Wednesday
                              </Text>
                              <Text style={{ color: "white", fontSize: 12 }}>
                                11:00 - 22:00
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Text style={{ color: "white", fontSize: 12 }}>
                                Thursday
                              </Text>
                              <Text style={{ color: "white", fontSize: 12 }}>
                                11:00 - 22:00
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Text style={{ color: "white", fontSize: 12 }}>
                                Friday
                              </Text>
                              <Text style={{ color: "white", fontSize: 12 }}>
                                11:00 - 22:00
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Text style={{ color: "white", fontSize: 12 }}>
                                Saturday
                              </Text>
                              <Text style={{ color: "white", fontSize: 12 }}>
                                11:00 - 22:00
                              </Text>
                            </View>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Text style={{ color: "white", fontSize: 12 }}>
                                Sunday
                              </Text>
                              <Text style={{ color: "white", fontSize: 12 }}>
                                11:00 - 22:00
                              </Text>
                            </View>
                          </View>

                          <View
                            style={{
                              margin: 15,
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              source={IMAGES.contact}
                              style={{
                                width: 20,
                                height: 20,
                                marginRight: 10,
                                resizeMode: "contain",
                              }}
                            />
                            <Text
                              style={{
                                color: "white",
                                fontSize: 12,
                                fontWeight: "500",
                              }}
                            >
                              +254 789 321 547
                            </Text>
                          </View>

                          <View
                            style={{
                              margin: 15,
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              source={IMAGES.delivery}
                              style={{
                                width: 20,
                                height: 20,
                                marginRight: 10,
                                resizeMode: "contain",
                              }}
                            />
                            <Text
                              style={{
                                color: "white",
                                fontSize: 12,
                                fontWeight: "500",
                              }}
                            >
                              KES 100 - KES 299
                            </Text>
                          </View>
                        </View>

                        {/* Box 2 */}
                        <View
                          style={{
                            marginVertical: 15,
                            borderRadius: 10,
                            backgroundColor: "#343643",
                            paddingVertical: 15,
                            paddingHorizontal: 4,
                          }}
                        >
                          <View style={{ margin: 15 }}>
                            <Text
                              style={{
                                marginTop: 3,
                                color: "white",
                                fontSize: 15,
                                fontWeight: "600",
                              }}
                            >
                              {merchantData.name}
                            </Text>
                            <Text
                              style={{
                                marginTop: 3,
                                color: "white",
                                fontSize: 12,
                                fontWeight: "550",
                              }}
                            >
                              About
                            </Text>
                            <Text
                              style={{
                                marginTop: 3,
                                color: "white",
                                fontSize: 12,
                              }}
                            >
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nam eos quisquam, optio dicta nisi nulla,
                              voluptatibus, voluptatem cumque et adipisci
                              cupiditate ipsum aliquid ea in dolorum. Qui
                              perspiciatis unde non. Lorem ipsum dolor sit amet
                              consectetur adipisicing elit. Nam eos quisquam,
                              optio dicta nisi nulla, voluptatibus, voluptatem
                              cumque et adipisci cupiditate ipsum aliquid ea in
                              dolorum. Qui perspiciatis unde non.
                            </Text>
                          </View>
                        </View>
                      </View>
                    ) : (
                      <View
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          marginVertical: 10,
                        }}
                      >
                        <Text style={{ color: "white" }}>No Reviews</Text>
                      </View>
                    )}
                  </View>
                </ScrollView>
              </View>
            </View>
          </View>
        </Modal>
      </View>
    </Layouts>
  );
}

const SingleProduct = ({ item }) => (
  <View
    style={{
      flex: 1,
      flexDirection: "row",
      flexWrap: "wrap",
      alignContent: "flex-start",
      marginBottom: 5,
    }}
  >
    <View
      style={{
        width: "50%", // 50% -> 2 columns | 33% -> 3 columns | 25% -> 4 columns
        height: "140px",
        marginVertical: 12,
      }}
    >
      <View
        style={{
          padding: 3,
          margin: 3,
          height: "calc(100% - 8px)",
          width: "200%",
        }}
      >
        <Image
          source={item.image}
          style={{
            width: "100%",
            height: 120,
            borderRadius: 8,
            marginBottom: 5,
          }}
        />
        <Text style={[styles.title, { fontWeight: 700 }]}>{item.name}</Text>
        <Text style={styles.title}>Ksh. {item.price}</Text>
      </View>
    </View>
  </View>
);

const { width, height } = Dimensions.get("screen");        {/* <Text style={[styles.title, { fontWeight: 700 }]}>john</Text> */}

const styles = StyleSheet.create({
  container:{
backgroundColor:COLORS.backgroundDark,
  },
  dropdown: {
    position: "absolute",
   // width: hp(20),
   width:"20%",
    backgroundColor: "rgb(3, 3, 56)",
    top: 20,
    left: -50,
    height: 0.5,
    padding: hp(2),
    zIndex: 1,
    borderRadius: 8,
  },
  warning: {
    width: "96%",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "red",
    borderRadius: 15,
    marginVertical: 20,
    paddingVertical: 10,
    marginHorizontal: 5,
  },
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.backgroundDark,
    position: "relative",
    maxWidth: 430,
  },
  navigationLinks: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 15,
    paddingVertical: 15,
    zIndex: 1,
  },
  offerViewPage: {
    width: "100%",
    height: "100%",
    margin: 0,
  },
  topImageView: {
    width: "100%",
    height: hp(20),
    position: "relative",
  },
  imagecover: {
    width: "100%",
    height: "100%",
  },
  barnextdoorbackground: {
    width: "100%",
    height: "100%",
  },
  imageZindexValues: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 60,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingHorizontal: 10,
  },
  avatarImageDisplay: {
    width: 40,
    height: 40,
    borderRadius: 100,
    overflow: "hidden",
    backgroundColor: COLORS.gray,
  },
  imageset: {
    width: "100%",
    height: "100%",
  },
  textPreview: {
    marginLeft: 5,
  },
  title: {
    ...FONTS.fineText,
    fontSize: 13,
  },
  bottomContentView: {
    width: "100%",
    paddingHorizontal: 8,
  },
  searchContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 2,
  },
  buttonCategories: {
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 5,
    flexDirection: "row",
    marginTop: 12,
  },
  buttonflex: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingVertical: 2,
    borderRadius: 10,
    width: "100%",
  },
  buttonflexGroup: {
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    paddingVertical: 2,
    backgroundColor: COLORS.backgroundLight,
    borderRadius: 10,
  },
  cartflex: {
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    paddingVertical: 2,
  },
  filterOffersBtns: {
    borderRadius: 100,
    paddingHorizontal: 10,
    paddingVertical: 2,
  },
  textColor: {
    ...FONTS.fineText,
    fontSize: 14,
  },
  cartContainer: {
    backgroundColor: COLORS.backgroundLight,
    width: 20,
    height: 20,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  cartCounter: {
    position: "absolute",
    top: -15,
    right: -5,
    backgroundColor: "red",
    width: 15,
    height: 15,
    borderRadius: 100,
    textAlign: "center",
    ...FONTS.smallfineText,
    fontSize: 11,
  },
  itemsContainer: {
    width: "100%",
    marginTop: 10,
    paddingHorizontal: 5,
  },
  titleFIlter: {
    paddingVertical: 5,
    width: "100%",
  },
  titleHeadingFilter: {
    ...FONTS.bigsubtext,
    fontSize: 14,
  },
  singleOfferView: {
    marginTop: 2,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: COLORS.backgroundLight,
    borderRadius: 10,
    height: 83,
    width: "100%",
    paddingHorizontal: 5,
  },
  singleOfferImg: {
    height: 70,
    width: 70,
    birderRadius: 10,
    overflow: "hidden",
  },
  singleOfferTagImg: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
  },
  offertextsDesc: {
    marginLeft: 15,
    fontSize: 14,
  },
  amountOffer: {
    ...FONTS.fineText,
  },
  handleChnageCart: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  cartBtnsOutline: {
    width: 20,
    height: 20,
    textAlign: "center",
    borderRadius: 100,
    borderWidth: 1,
    borderColor: COLORS.white,
    justifyContent: "center",
    alignItems: "center",
  },
  actions: {
    ...FONTS.bigsubtext,
    fontSize: 14,
  },
  cartDisplayCapacity: {
    marginHorizontal: 10,
    ...FONTS.fineText,
  },

  // Modal styles
  modalContainer: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    paddingTop: 5,
    width: wp(100),
    height: hp(100),
    maxWidth: 430,
  },
  modalContent: {
    backgroundColor: COLORS.backgroundDark,
    padding: 20,
    width: "100%",
    height: hp(90),
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    elevation: 5,
  },
  modalContainerGroupPayment: {
    backgroundColor: COLORS.backgroundDark,
    padding: 20,
    width: "100%",
    height: "35%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    elevation: 5,
  },
  modalContainerGroupPaymentLink: {
    backgroundColor: COLORS.backgroundDark,
    padding: 20,
    width: "100%",
    height: "45%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    elevation: 5,
  },
  madalPayAmount: {
    width: "100%",
    height: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  modelTextColor: {
    paddingVertical: 5,
    width: "100%",
    textAlign: "center",
    ...FONTS.bigsubtext,
  },
  barTopSectionView: {
    borderTopWidth: 3,
    height: 1,
    borderTopColor: "white",
    width: 52,
    borderRadius: 100,
    marginBottom: 5,
    marginTop: -20,
  },
  topCloseModal: {
    width: "100%",
    paddingHorizontal: 5,
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
  },
  windowDisplay: {
    width: "100%",
    flex: 1,
    paddingVertical: 5,
  },
  groupTitle: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
  },
  modalButtonOrder: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: 40,
    maxWidth: 430,
  },
  modalButtonOrderGroupChart: {
    marginVertical: 10,
  },
  singleInput: {
    padding: 10,
    borderRadius: 10,
    backgroundColor: COLORS.backgroundLight,
    marginVertical: 5,
    color: COLORS.gray,
    border: "none",
  },
  button: {
    width: "90%",
  },
  buttonGroup: {
    width: "96%",
    marginVertical: 5,
    fontSize: 14,
  },
  linktoCopy: {
    width: "98%",
    backgroundColor: COLORS.backgroundLight,
    paddingVertical: 10,
    paddingHorizontal: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 10,
  },

  inputsSendMethods: {
    marginTop: 10,
    maxWidth: "98%",
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingVertical: 10,
  },
  paymentsAmountDisplays: {
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  paymentsInputs: {
    padding: 10,
    borderRadius: 10,
    backgroundColor: COLORS.backgroundLight,
    marginVertical: 10,
    color: COLORS.gray,
    border: "none",
    width: "100%",
  },
  singleButton: {
    width: 50,
    height: 80,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 5,
  },
  imagePay: {
    width: 30,
    height: 30,
    borderRadius: 100,
    backgroundColor: COLORS.gray,
    overflow: "hidden",
    alignSelf: "center",
    marginLeft: 10,
  },

  imagePayIcon: {
    width: "100%",
    height: "100%",
    borderRadius: 100,
  },
});
export default ProductsScreen;
