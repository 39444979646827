import { COLORS, IMAGES, FONTS } from "../../../constants/index";
import {
  StyleSheet,
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
  Image,
  FlatList,
  Modal,
} from "react-native";
import React, { useEffect, useState } from "react";
import { CheckCircle2 } from "lucide-react";
import Layouts from "../../Layouts/ScreenView";
import Button from "../../../components/Button";
import { useCart } from "../../../context/SinglecartContext";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { useGroupCart } from "../../../context/GroupmanagerContext";

function SuccessScreen({ navigation, route, layoutState }) {
  const { groupno, memberId } = route.params || {};
  const { merchantData } = route.params || {};
  const { dispatch } = useCart();
  const { clearCart, state } = useGroupCart();

  // transaction details
  const [orderNumber, setOrderNumber] = useState("");
  const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState("");
  const [orderType, setOrderType] = useState("");
  const [loading, setLoading] = useState(false);

  const generalClearCart = () => {
    if (groupno && memberId) {
      clearCart(groupno);
    }
    else {
      dispatch({ type: "CLEAR_CART", payload: groupno });
    }
  };

  const cleanUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      const merch = parsedUrl.searchParams.get("merch");

      // Construct a new URL keeping only `merch`
      return `${parsedUrl.origin}${parsedUrl.pathname}?merch=${merch}`;
    } catch (error) {
      console.error("Invalid URL:", error);
      return url; // Return the original URL if there's an error
    }
  };

  const { totalAmountPaid, brandname, billStatus, paymentOptionsPlan } =
    route.params;
  const { responseTransactionRef, responseTransactionId } = route.params;
  useEffect(() => {
    const controller = new AbortController();
    const fetchPurchaseDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://backoffice.route.money/api/v1/business/checkout/order/details/",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              transaction_reference: responseTransactionRef,
            }),
            signal: controller.signal,
          }
        );

        const responseData = await response.json();
        if (responseData) {
          setLoading(false);
          setCurrency(responseData.order.transaction.currency);
          setAmount(responseData.order.total_order_amount);
          setOrderNumber(responseData.order.order_number);
          setOrderType(responseData.order.order_type);
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error fetching details:", error);
        }
      }
    };

    fetchPurchaseDetails();
    return () => controller.abort(); // Cleanup on unmount
  }, []);

  const navigateToOffers = () => {
    let currentURL = window.location.href;
    generalClearCart();
    //navigation.navigate("OfferScreen", { merchantData });
    const homeUrl = cleanUrl(currentURL);
    // console.log("Navigating with cleaned URL:", homeUrl);
    window.history.replaceState(null, "", homeUrl);

    navigation.navigate("HomeScreen");

    // navigation.navigate("OfferScreen", "woodpark");
  };

  const handleBillViewAMount = () => {
    navigation.navigate("RecieptScreen", { showPersonaStatus: true });
  };

  return (
    <Layouts>
      <View style={styles.container}>
        <View style={styles.topStatus}>
          <CheckCircle2 size={70} color="green" />
          <Text
            style={{
              ...FONTS.smallfineText,
              fontSize: 14,
              paddingVertical: 10,
              textAlign: "center",
              lineHeight: 20,
            }}
          >
            You have succesfully placed an order type, {orderType}.
          </Text>
          <TouchableOpacity>
            <Text
              style={{
                ...FONTS.smallfineText,
                fontSize: 14,
                paddingTop: 20,
                paddingBottom: 10,
                textAlign: "center",
                lineHeight: 20,
                color: COLORS.blue,
              }}
            >
              Order Details {paymentOptionsPlan && paymentOptionsPlan}
            </Text>
          </TouchableOpacity>

          {!loading ? (
            <View>
              <Text
                style={{
                  ...FONTS.smallfineText,
                  fontSize: 14,
                  paddingVertical: 5,
                  textAlign: "center",
                  lineHeight: 20,
                }}
              >
                Order Number: {orderNumber}
              </Text>

              <Text
                style={{
                  ...FONTS.smallfineText,
                  fontSize: 14,
                  paddingVertical: 5,
                  textAlign: "center",
                  lineHeight: 20,
                }}
              >
                Order Type: {orderType}
              </Text>

              <Text
                style={{
                  ...FONTS.smallfineText,
                  fontSize: 14,
                  paddingVertical: 5,
                  textAlign: "center",
                  lineHeight: 20,
                }}
              >
                Amount Paid: {currency}. {amount}
              </Text>
            </View>
          ) : (
            <ActivityIndicator size={45} color="green" />
          )}
        </View>
        {billStatus ? (
          <View style={styles.footerSections}>
            <Button
              text="Done"
              onPress={navigateToOffers}
              style={styles.buttonFooters}
            />
            <Button
              text="View Bill Status"
              onPress={handleBillViewAMount}
              style={styles.buttonFooters}
            />
          </View>
        ) : (
          <View style={styles.footerSection}>
            <Button
              text="Done"
              onPress={navigateToOffers}
              style={styles.buttonFooter}
            />
            {/* <Text style={{...FONTS.smallfineText, color: "skyblue", fontSize: 14, paddingVertical: 10}}>Download receipt</Text> */}
          </View>
        )}
      </View>
    </Layouts>
  );
}

const { width, height } = Dimensions.get("screen");
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.backgroundDark,
    position: "relative",
    maxWidth: 430,
    position: "relative",
  },
  topStatus: {
    justifyContent: "space-around",
    alignItems: "center",
    position: "absolute",
    top: 200,
    width: "60%",
  },
  footerSection: {
    width: "100%",
    position: "absolute",
    bottom: 40,
    right: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonFooter: {
    width: "60%",
  },
  footerSections: {
    width: "100%",
    position: "absolute",
    bottom: 40,
    right: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  buttonFooters: {
    width: "40%",
    marginRight: 5,
    fontSize: 11,
  },
});
export default SuccessScreen;
