import { COLORS, IMAGES, FONTS } from "../../../constants/index";
import {
  StyleSheet,
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  Image,
  FlatList,
  Modal,
} from "react-native";
import React from "react";
import { CheckCircle2, AlertCircle } from "lucide-react";
import Layouts from "../../Layouts/ScreenView";
import Button from "../../../components/Button";
import { useCart } from "../../../context/SinglecartContext";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { useGroupCart } from "../../../context/GroupmanagerContext";

function ErrorScreen({ navigation, route, layoutState }) {
  const { groupno, memberId } = route.params || {};
  const { merchantData } = route.params || {};
  const { dispatch } = useCart();
  const { clearCart, state } = useGroupCart();

  const generalClearCart = () => {
    if (groupno && memberId) {
      clearCart(groupno);
    }
    else {
      dispatch({ type: "CLEAR_CART", payload: groupno });
    }
  };

  const cleanUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      const merch = parsedUrl.searchParams.get("merch");

      // Construct a new URL keeping only `merch`
      return `${parsedUrl.origin}${parsedUrl.pathname}?merch=${merch}`;
    } catch (error) {
      console.error("Invalid URL:", error);
      return url; // Return the original URL if there's an error
    }
  };

  const navigateToOffers = () => {
    let currentURL = window.location.href;
    generalClearCart();
    //navigation.navigate("OfferScreen", { merchantData });
    const homeUrl = cleanUrl(currentURL);
    console.log("Navigating with cleaned URL:", homeUrl);
    window.history.replaceState(null, "", homeUrl);

    navigation.navigate("HomeScreen");

    // navigation.navigate("OfferScreen", "woodpark");
  };

  const handleBillViewAMount = () => {
    navigation.navigate("RecieptScreen", { showPersonaStatus: true });
  };

  return (
    <Layouts>
      <View style={styles.container}>
        <View style={styles.topStatus}>
          <AlertCircle size={70} color="orange" />
          <Text
            style={{
              ...FONTS.smallfineText,
              fontSize: 14,
              paddingVertical: 10,
              textAlign: "center",
              lineHeight: 20,
            }}
          >
            An error Occured. Please try again
          </Text>
          <Text
            style={{
              ...FONTS.smallfineText,
              fontSize: 14,
              paddingVertical: 10,
              textAlign: "center",
              lineHeight: 20,
              color: COLORS.blue,
            }}
          >
            {/* Order Status {paymentOptionsPlan && paymentOptionsPlan} */}
          </Text>
        </View>

        <View style={styles.footerSection}>
          <Button
            text="Home"
            onPress={navigateToOffers}
            style={styles.buttonFooter}
          />
          {/* <Text style={{...FONTS.smallfineText, color: "skyblue", fontSize: 14, paddingVertical: 10}}>Download receipt</Text> */}
        </View>
      </View>
    </Layouts>
  );
}

const { width, height } = Dimensions.get("screen");
const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.backgroundDark,
    position: "relative",
    maxWidth: 430,
    position: "relative",
  },
  topStatus: {
    justifyContent: "space-around",
    alignItems: "center",
    position: "absolute",
    top: 200,
    width: "60%",
  },
  footerSection: {
    width: "100%",
    position: "absolute",
    bottom: 40,
    right: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonFooter: {
    width: "60%",
  },
  footerSections: {
    width: "100%",
    position: "absolute",
    bottom: 40,
    right: 0,
    left: 0,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  buttonFooters: {
    width: "40%",
    marginRight: 5,
    fontSize: 11,
  },
});
export default ErrorScreen;
